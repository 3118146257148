:root {
  --nav-bg: #dfdbe6;
  --main-bg: #E5E5E5;
  --box-color: white;
  --control-grey: #333333;
  --modal-grey: #e7e7e7;
  --nav-size: 64px;
  --footer-size: 64px;
  --mobile-footer-size: 196px;
  --spacing-1: 16px;
  --spacing-2: 24px;
  --spacing-3: 32px;
  --spacing-4: 48px;
  --spacing-5: 64px;
  --small-input: 28px;
  --large-input: 200px;
  /* Toast Notifications*/
  --toastify-toast-width: 388px;
  --toastify-font-family: OurCustomFont;
  --toastify-text-color-light: #ffffff;
  --toastify-text-color-dark: #000000;
  --splash-color: #121212;

  /* Scrollbar */
  --scrollbar-track-color: transparent;
  --scrollbar-thumb-color: #F2F2F2;
  --scrollbar-hover-color: #FFFFFF;
}

@font-face {
  font-family: Inter;
  font-weight: 1600;
  /* orig: 100 900*/
  font-display: swap;
  --toastify-color-progress-dark: #bb86fc;
  font-style: oblique 0deg 10deg;
  src: url('assets/Inter.var.woff2') format("woff2");
}

@font-face {
  font-family: SK-Primo;
  src: url('./assets/skPrimo.ttf');
}

@font-face {
  font-family: Montserrat;
  src: url('./assets/Montserrat-Regular.ttf');
}

@font-face {
  font-family: VCR;
  src: url('./assets/VCR_OSD_MONO_1.001.ttf');
}

@font-face {
  font-family: IBM;
  src: url('./assets/IBMPlexMono-Regular.ttf');
}

@font-face {
  font-family: Technology;
  src: url('./assets/Technology.ttf');
}

@font-face {
  font-family: Bebas;
  src: url('./assets/Bebas-Regular.ttf');
}

body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: OurCustomFont;
}

@media only screen and (max-width: 1200px) {
  body {
    position: fixed;
    height: -webkit-fill-available;
  }

  html {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
}

#backgroundVideo {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

nav {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  height: var(--nav-size);
  width: 100%;
  background-color: var(--nav-bg);
}

.hamburgerIcon {
  margin-left: 16px;
  background: none;
  border: none;
}

.mobileDropdownContainer {
  left: 0;
  top: 45px !important;
  width: 100%;
  text-align: center;
}
.mobileDropdownContainer a {
  width: 100% !important;
  display: block;
}

#troacoTopNav {
  margin-top: 30px;
  font-family: Montserrat;
  align-items: flex-start;
  justify-content: flex-end;
  background: transparent;
  position: absolute;
  z-index: 2;
}

#troacoBottomNav {
  bottom: 32px;
  left: 53px;
  width: 60%;
  text-align: center;
  top: unset;
  background: transparent;
  justify-content: space-between;
  align-items: baseline;
  position: absolute;
  z-index: 2;
}

#troacoSongMenu {
  display: flex;
  flex-wrap: wrap-reverse;
  width: 100%;
  list-style-type: none;
  justify-content: flex-start;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #BDBDBD;
}
#troacoSongMenu li {
  margin-bottom: 23px;
  margin-right: 32px;
}

.troacoSongSelected {
  font-weight: 700;
  color: #F2F2F2;
}

#troacoSocialLinks {
  position: absolute;
  bottom: var(--spacing-3);
  right: 250px;
  display: flex;
  flex-direction: row;
}

#troacoSocialLinks a {
  margin-top: 4px;
  margin-left: var(--spacing-1);
}

#troacoLogo {
  position: absolute;
  bottom: var(--spacing-3);
  right: 46px;
  margin: 0;
}

.logo {
  flex-grow: 1;
  font-family: SK-Primo;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  text-align: center;
  display: inherit;
  justify-content: center;
  color: #333333;
  padding-left: var(--spacing-2);
}

.controls {
  flex-shrink: 0;
}

.controlsLast {
  margin-right: 120px;
}

@media only screen and (max-width: 1200px) {
  #troacoTopNav {
    justify-content: space-between;
    margin-top: 16px;
  }
  #troacoBottomNav {
    flex-wrap: wrap;
    align-items: flex-end;
    bottom: 0;
    left: 0;
    width: 100%;
    height: max-content;
    z-index: 1;
  }
  #troacoSongMenu li {
    margin-bottom: 12px;
  }
  .controlsLast {
    margin-right: 16px;
  }
}

.controlsButton {
  background: transparent;
  border: none;
  outline: none;
  display: block;
  cursor: pointer;
}

.troacoMenuButton {
  margin-right: 32px;
  margin-top: 7px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-decoration: none;
  color: #F2F2F2;
}

.controlsButton img:hover {
  filter: brightness(2.5);
}

.connectButton {
  width: 90px;
  height: 30px;
  background: transparent;
  border: solid 2px gray;
  border-radius: 5px;
  margin-right: 7px;
}

.walletBadge {
  display: flex;
  align-items: center;
  background-color: #e9e6ed;
  border-radius: 25px;
  border: transparent;
  height: 30px;
  padding-left: 14px;
  padding-right: 14px;
  margin-right: 7px;
  cursor: pointer;
  /*#4d00b4*/
}

.wallet {
  position: absolute;
  top: var(--nav-size);
  right: 120px;
  width: 480px;
  background-color: var(--box-color);
  box-shadow: 0 0 6px rgba(35, 173, 278, 1);
  z-index: 9998;
}

.upperWalletLine {
  margin-top: 16px;
  margin-bottom: 16px;
}

.accountBalanceBox {
  width: 80%;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 16px;
  color: #4d00b4;
  text-align: center;
  background: #fbf9fe;
}

.accountBalanceBoxUpper {
  padding-top: 16px;
  padding-bottom: 4px;
  margin: 0px;
}

.accountBalanceBoxDowner {
  padding-bottom: 16px;
  margin: 0px;
  font-size: 22px;
  font-weight: bold;
}

.dot {
  height: 5px;
  width: 5px;
  margin-right: 6px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
}

.redDot {
  background-color: #EE0000;
}

.blueDot {
  background-color: #4d00b4;
}

#dropdownContainer {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 1px;
  right: 478px;
  top: var(--nav-size);
  z-index: 9998;
}

#dropdownContainer a {
  font-family: Montserrat;
  text-transform: uppercase;
  text-decoration: none;
  color: #333333;
  width: 180px;
  padding: 10px;
  margin: 0;
  border-bottom: solid #E0E0E0 1px;
}

footer {
  position: absolute;
  background: transparent;
  z-index: 2;
  margin-top: 14px;
  margin-bottom: 14px;
  display: flex;
  bottom: 0;
  left: 0;
  height: var(--footer-size);
  width: 50%;
  align-items: center;
}

footer ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-left: 120px;
  margin-right: 120px;
  list-style: none;
}

footer ul li {
  margin-right: 49px;
}

footer ul li a {
  font-family: Montserrat;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  letter-spacing: 0.05em;
  color: #121212;
  font-weight: 400;
}

main {
  background-color: var(--main-bg);
  background: transparent;
  height: 100vh;
  background-size: cover;
  overflow: hidden;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1200px) {
  footer {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    width: 100vw;
    height: var(--mobile-footer-size);
  }

  footer ul {
    flex-direction: column;
    width: 100%;
    justify-content: space-evenly;
    margin: 0;
    margin-bottom: 16px;
  }

  footer ul li {
    margin: 8px;
  }

  main {
    overflow-y: scroll;
    height: 100vh;
    width: 100vw;
  }
}

#adminLogo {
  display: block;
}

#adminMain {
  overflow: scroll;
  background: var(--main-bg);
}

.adminMain {
  padding: var(--spacing-5);
}

.connectWallet {
  padding-top: var(--spacing-5);
  height: 100vh;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}

.walletFlexPlaceholder {
  flex-grow: 1;
}

.troacoWalletBadge {
  background: transparent;
  font-family: Montserrat;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  color: #FFFFFF;  
  font-size: 16px;
  width: 219px;
  height: 36px;
  margin-right: 32px;
  border: 2px solid #F2F2F2;
  border-radius: 100px;
}

.troacoWalletBadge:hover {
  background: #F2F2F2;
  border: 1px solid #F2F2F2;
  color: #333333;
}

.troacoWalletContainer {
  position: absolute;
  font-family: Montserrat;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  top: 45px;
  width: calc(217px - 2*24px);
  height: calc(136px - 2*24px);
  padding: 24px;
  text-transform: uppercase;
  background: transparent;
  border: 2px solid #F2F2F2;
  border-radius: 20px;
  right: var(--spacing-3);
  z-index: 9998;
}

#troacoDisconnect {
  background: transparent;
  width: 131px;
  height: 23px;
  border: 2px solid #F2F2F2;
  border-radius: 100px;
  font-family: Montserrat;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

#troacoDisconnect:hover {
  background: #F2F2F2;
  border: 1px solid #F2F2F2;
  color: #333333;
}

#troacoWalletBalanceFlex {
  display: flex;
  align-items: center;
}

#troacoWalletBalanceFlex>* {
  margin-top: 0;
  margin-bottom: 0;
}

.walletFlexPlaceholder {
  flex-grow: 1;
}

#troacoWalletNearLogo {
  margin-left: 5px;
}

#troacoWalletDollarFlex {
  display: flex;
  margin-top: 8px;
  margin-bottom: 20px;
}

#troacoWalletDollarFlex>* {
  margin-top: 0;
  margin-bottom: 0;
}

#troacoWalletButtonContainer {
  display: flex;
  justify-content: center;
}


.title {
  margin: auto;
  margin-top: 48px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #000000;
}

.fieldName {
  display: block;
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-2);
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.nftTitleInput {
  width: 99%;
  height: var(--small-input);
}

.descInput {
  width: 99%;
  height: var(--large-input);
}

.priceInput {
  width: 99%;
  height: 28px;
  background: white url('./assets/logo-black.svg') right no-repeat;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}

.royaltyList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.royaltyElement {
  display: flex;
}

.royaltyList div {
  margin-right: 24px;
  flex-grow: 1;
}

.royaltyList div:last-child {
  flex-grow: 0;
  margin-right: 0;
}

.royaltyList div input {
  width: 100%;
  height: 28px;
}

.royaltyList div label {
  margin-bottom: 16px;
  margin-top: 16px;
}

.placeholderLabel {
  display: block;
  visibility: hidden;
}

.royaltyButton {
  border: none;
  background: transparent;
  display: inline;
  font-family: IBM;
  font-size: 40px;
  margin-left: 16px;
}

.smallRoyaltyLabel {
  display: block;
  font-size: 16px;
}

.previewBox {
  display: grid;
  padding: var(--spacing-3);
  box-shadow: 0 0 6px rgba(35, 173, 278, 1);
  background-color: var(--box-color);
}

.previewImage {
  width: 100%;
}

.previewBoxTitle {
  margin-top: var(--spacing-3);
  margin-bottom: var(--spacing-2);
}

.price {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  height: var(--spacing-4);
}

.priceP {
  font-size: 22px;
  font-weight: bold;
  margin-right: 10px;
}

.previewBoxItem {
  grid-column-start: 1;
  grid-column-end: 4;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .previewBoxItem {
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-2);
  }
}

.imageDropZone {
  width: 99%;
  height: var(--spacing-5);
  border: solid 2px #009aff;
  background-color: #f0f9ff;
  border-style: dashed;
  border-radius: 2px;
  text-align: center;
}

.imageDropZoneActive {
  background-color: white;
}

.imageDropZone img {
  padding: 20px;
}

.smallUploader {
  width: max-content;
  height: 28px;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 5px;
  border: solid 2px #009aff;
  background-color: #f0f9ff;
  border-style: dashed;
  border-radius: 27px;
  text-align: center;
}

.smallUploader button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.infoDiv {
  color: #bdbdbf;
  display: flex;
  margin-bottom: -16px;
}

.infoDiv p {
  margin-left: 8px;
}

.mainButton {
  margin-top: 80px;
  border: none;
  padding: 11.5px var(--spacing-3);
  color: white;
  background: #333333;
  border-radius: 3px;
}

.mainButton:hover {
  background: #828282;
  cursor: pointer;
}

.mainButton:disabled {
  background: #C4C4C4;
}

#disconnectButton {
  margin-top: 0px;
  margin-bottom: 32px;
}

.buttonContainer {
  text-align: center;
}

.adminFlexBox {
  display: flex;
}

.nft-details {
  margin-right: 64px;
  width: 70%;
}

.preview {
  position: static;
  width: 30%;
}

h1 {
  font-family: Montserrat;
  font-size: 40px;
  /*margin-top: calc(var(--nav-size) + 72px);*/
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
}

.musicControlsButton {
  flex-shrink: 1;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.musicControlsSlider {
  flex-grow: 1;
  width: 80%;
  height: 2px;
  margin-left: 20px;
  margin-right: 20px;
  /*background-color: var(--control-grey);*/
  background-color: var(--splash-color);
}

.musicControlsSliderWhite {
  background-color: #F2F2F2;
}

.musicControlsSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 20px;
  /*border: 7px solid var(--control-grey);*/
  border: 12px solid var(--splash-color);
  border-top: none;
  border-bottom: none;
  /*background-color: var(--control-grey);*/
  background-color: var(--splash-color);
  margin-top: 0px;
  border-radius: 0;
}

.musicControlsSliderWhite::-webkit-slider-thumb {
  border: 12px solid white;
  border-top: none;
  border-bottom: none;
  background-color: white;
}

.musicControlsSlider::-moz-range-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 20x;
  /*border: 7px solid var(--control-grey);*/
  border: 12px solid var(--splash-color);
  border-top: none;
  border-bottom: none;
  /*background-color: var(--control-grey);*/
  background-color: var(--splash-color);
  margin-top: -5px;
  border-radius: 0;
}

.musicControlsSliderWhite::-moz-range-thumb {
  border: 12px solid white;
  border-top: none;
  border-bottom: none;
  background-color: white;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 15rem;
}

.loadingLabel {
  font-family: VCR;
  text-transform: uppercase;
}

@keyframes labelFadeIn {
  0% {
    transform: scale(0.65);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes labelFadeOut {
  0% {
    transform: scale(1.33);
  }

  100% {
    transform: scale(1);
  }
}

.dropdownContainer {
  position: absolute;
  top: var(--nav-size);
  right: var(--spacing-3);
  width: 480px;
  background-color: var(--box-color);
  box-shadow: 0 0 6px rgba(35, 173, 278, 1);
  z-index: 9998;
}

#wallet {
  text-align: center;
}



.buyModal {
  position: absolute;
  display: grid;
  grid-template-areas: 'bar'
    'content';
  top: 100px;
  left: 16%;
  width: 1000px;
  /*background-color: rgba(255, 255, 255, 0.90);*/
  border: 2px solid var(--control-grey);
  box-shadow: 0px 1px 4px rgba(0,0,0, 0.2);
  background-color: var(--modal-grey);
  z-index: 3;
}

.troacoModal {
  position: absolute;
  display: grid;
  width: 55vw;
  height: 72vh;
  top: calc(50vh - (72vh/2));
  left: calc(50vw - (55vw/2));
  z-index: 3;
}

#troacoModalContent {
  grid-area: content;
  padding: var(--spacing-3);
  height: max-content;
  display: grid;
  grid-auto-columns: 1fr 1fr auto;
  grid-auto-rows: min-content;
  grid-auto-flow: column;
  grid-template-areas: 'picture right close'
                       'audio buttons close';
}

#troacoModalPicture {
  grid-area: picture;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
}

#troacoModalPicture img {
  width: 100%;
  object-fit: contain;
  filter: drop-shadow(0px 10px 100px rgba(0, 0, 0, 0.5));
  border-radius: 10px;
}

#troacoModalRightSide {
  grid-area: right;
  background: rgba(217, 217, 217, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding-left: var(--spacing-1);
  padding-right: 8px;
  display: flex;
  flex-direction: column;
}

#troacoModalClose {
  grid-area: close;
  width: 35px;
  height: 35px;
  border: none;
  background: transparent;
}

.troacoModalGuestbookRightSide {
  background: transparent !important;
  backdrop-filter: none !important;
  height: 100% !important;
}

.troacoModalRightSideContent {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #F2F2F2;
  margin-top: 8px;
  flex-grow: 1;
}

#troacoModalTitleLine {
  display: flex;
  flex-direction: row;
}

.troacoModalLeftSpacer {
  margin-left: 64px;
}

.troacoModalBigText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 16px;
  margin-bottom: 16px;
}

#troacoModalGuestbookCurrentAccount {
  background: rgba(217, 217, 217, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
}

#troacoModalAudio {
  grid-area: audio;
  margin-top: 20px;
  padding-right: 25px;
  display: flex;
  align-items: center;
}

#troacoModalButtons {
  text-align: end;
  margin-top: 16px;
}

#troacoModalSwitchBox {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-self: end;
  margin-bottom: 16px;
}

.troacoModalMenuKnob {
  border: none;
  background: transparent;
  font-family: Montserrat;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #BDBDBD;
}

.troacoModalMenuSelected {
  text-decoration: underline;
  font-weight: 600;
  color: #F2F2F2;
}

#troacoModalListContainer {
  height: 37vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
  /* Firefox scrollbar styling is different*/
  padding-left: 0;
  padding-right: 0px;
}

.troacoModalSmallGlass {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  grid-auto-flow: row;
  grid-template-areas: 'upper_line'
  'bottom_line';
  width: 94%;
  background: rgba(217, 217, 217, 0.2);
  border-radius: 7px;
  list-style-type: none;
  margin-bottom: 16px;
  padding: 8px;
}

.toacoModalSmallGlassUpperLine {
  grid-area: upper_line;
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: row;
}

.troacoModalSmallGlassBottomLine {
  grid-area: bottom_line;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.troacoModalSmallGlassElement {
  margin-top: 0;
  margin-bottom: 0;
}

.troacoModalStrongText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.troacoModalWeakText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #BDBDBD;
}

.troacoSmallBuy {
  background: url('./assets/buy_small.svg') no-repeat;
  width: 41px;
  height: 24px;
  /*background-repeat: no-repeat;*/
  border: none;
  color: white;
  background-color: none;
  border-radius: 2px;
  margin-bottom: -4px;
}

.troacoSmallBuy:hover {
  background: url('./assets/buy_small_hover.svg') no-repeat;
}

.troacoModalSmallGlassGenMargin {
  margin-left: 24px;
}

.troacoModalSmallGlassPriceMargin {
  margin-left: 8px;
}

/* Scrollbar */
#troacoModalListContainer::-webkit-scrollbar {
  width: 7px;                                                                                                   /* scrollbar width */
}
#troacoModalListContainer::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);                                                                     /* scrollbar Track */
  background: -webkit-linear-gradient(to right, var(--scrollbar-track-color), var(--scrollbar-track-color));    /* fallback for old browsers */
  background: linear-gradient(to right, var(--scrollbar-track-color), var(--scrollbar-track-color));            /* Chrome 10-25, Safari 5.1-6 */
  border-radius: 10px;                                                                                          /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);                                                                     /* scrollbar Handle */
  background: -webkit-linear-gradient(to right, var(--scrollbar-thumb-color), var(--scrollbar-thumb-color));    /* fallback for old browsers */
  background: linear-gradient(to right, var(--scrollbar-thumb-color), var(--scrollbar-thumb-color));            /* Chrome 10-25, Safari 5.1-6 */
  border-radius: 10px;                                                                                          /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
#troacoModalListContainer::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-hover-color);                                                                     /* scrollbar Handle on hover */
}
/* Scrollbar*/

#transferPopupWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(17, 17, 17, 0.4);
  z-index: 4;
}

#transferPopup {
  position: absolute;
  padding: 32px;
  top: calc(50% - (460px / 2));
  left: calc(50% - (400px / 2));
  background: #F8FDFD;
  width: 400px;
  min-height: 260px;
  box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  z-index: 5;
}

.transferPopupTitle {
  font-family: Montserrat;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
  margin-top: 0;
  margin-bottom: 32px;
}

.transferPopupNormalText {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 0;
  padding: 0;
}

.transferPopupInline {
  display: inline;
}

.transferPopupBoldText {
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
  margin: 0;
  padding: 0;
}

.transferPopupInput {
  box-sizing: border-box;
  width: 336px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
}

.transferPopupButton {
  width: 125px;
  height: 38px;
  background: #121212;
  border: none;
  border-radius: 50px;
  font-family: Montserrat;
  text-transform: uppercase;
  font-weight: 450;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  margin-top: 32px;
}

.transferPopupButton:hover {
  cursor: pointer;
  background: #FFFFFF;
  border: 2px solid #121212;
  color: #121212;
}

@media only screen and (max-width: 1200px) {
  #transferPopup {
    left: 0;
    bottom: 200px;
    top: auto;
    width: calc(100% - 64px);
  }

  #transferPopupWrapper {
    overflow: hidden;
    background: rgba(17, 17, 17, 0.8);
  }
  .troacoModal {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: scroll;
  }
  #troacoModalContent {
    padding: 0;
    grid-auto-columns: 1fr auto;
    grid-auto-rows: min-content;
    grid-auto-flow: column;
    grid-template-areas: 'close'
                         'picture'
                         'right'
                         'audio'
                         'buttons';
  }
  #troacoModalClose {
    width: 100%;
    text-align: right;
  }
  #troacoModalPicture {
    padding-left: 16vw;
    padding-right: 16vw;
    margin-bottom: 32px;
    margin-top: 32px;
  }
  #troacoModalAudio {
    padding-right: 0;
  }
  #troacoModalButtons {
    text-align: center;
    margin-top: 32px;
    margin-bottom: 64px;
  }
  #modalMainButton {
    margin-left: 0 !important;
  }
  #troacoModalSwitchBox {
    width: 100%;
    align-self: center;
    margin-top: 32px;
  }
  #troacoModalGuestbookCurrentAccount {
    margin-top: 32px;
  }
}

.scroll {
  overflow-y: scroll;
}

.F2White {
  color: #F2F2F2;
}

#guestbookMessages {
  /*background: url('./assets/blurSvgBackground.svg');*/
  background: #333333;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 16px;
  height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
}

#guestbookInput {
  background: rgba(217, 217, 217, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: none;
  padding: 16px;
  margin-top: 20px;
  height: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #F2F2F2;
}

/* Scrollbar */
#guestbookMessages::-webkit-scrollbar {
  width: 7px;                                                                                                   /* scrollbar width */
}
#guestbookMessages::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);                                                                     /* scrollbar Track */
  background: -webkit-linear-gradient(to right, var(--scrollbar-track-color), var(--scrollbar-track-color));    /* fallback for old browsers */
  background: linear-gradient(to right, var(--scrollbar-track-color), var(--scrollbar-track-color));            /* Chrome 10-25, Safari 5.1-6 */
  border-radius: 10px;                                                                                          /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
#guestbookMessages::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);                                                                     /* scrollbar Handle */
  background: -webkit-linear-gradient(to right, var(--scrollbar-thumb-color), var(--scrollbar-thumb-color));    /* fallback for old browsers */
  background: linear-gradient(to right, var(--scrollbar-thumb-color), var(--scrollbar-thumb-color));            /* Chrome 10-25, Safari 5.1-6 */
  border-radius: 10px;                                                                                          /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
#guestbookMessages::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-hover-color);                                                                     /* scrollbar Handle on hover */
}
/* Scrollbar */

.guestbookMessage {
  display: flex;
  flex-wrap: wrap;
}

.guestbookMessageSender {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  flex-grow: 1;
  margin: 0;
}

.guestbookMessageTime {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #BDBDBD;
  margin: 0;
}

.guestbookMessageMessage {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #E0E0E0;
  margin-top: 8px;
  width: 100%;
}


#modalMainButton {
  width: 99px;
  height: 36px;
  border: none;
  color: white;
  background-color: transparent;
  border: 2px solid #F2F2F2;
  border-radius: 100px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-left: var(--spacing-3);
}

#modalMainButton:hover {
  background: #F2F2F2;
  border: 1px solid #F2F2F2;
  color: #333333;
  cursor: pointer;
}

#transferButton {
  width: 153px;
  height: 36px;
  /*background-repeat: no-repeat;*/
  border: none;
  color: white;
  background-color: transparent;
  border: 2px solid #F2F2F2;
  border-radius: 100px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-left: var(--spacing-3);
}

#transferButton:hover {
  background: #F2F2F2;
  border: 1px solid #F2F2F2;
  color: #333333;
  cursor: pointer;
}

/* a11y*/

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* Toast messages*/

/*  This rule has to be upper then success */

.Toastify__toast-theme--light {
  background-color: #4f2e6d;
  width: 388px;
  border-radius: 0px;
}

.Toastify__toast--success {
  background-color: #1c7c20;
}

.Toastify__toast--error {
  background-color: #ba0f76;
}

.messageTitle {
  font-weight: 600;
  font-size: 17px;
  font-style: bold;
  margin-bottom: var(--spacing-1);
}

.messageDesc {
  font-weight: 600;
  font-size: 14px;
}

.toastMargin {
  margin-left: var(--spacing-2);
}

.dropdownTitle {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-1);
  text-align: center;
}

.dropdownLine {
  /*color: #828282;*/
  width: 80%;
  border: none;
  border-top: 1px #f2f2f2 solid;
  height: 1px;
}

.notificationList {
  padding: 0;
  margin: var(--spacing-2);
  margin-bottom: var(--spacing-1);
}

.notificationList li {
  list-style: none;
  margin: 0;
  padding: 0 0 0 60px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 40px;
  margin-bottom: var(--spacing-1);
}

.successNotification {
  background-image: url('assets/ok-256.png');
}

.errorNotification {
  background-image: url('assets/ok-256.png');
}

.infoNotification {
  background-image: url('assets/ok-256.png');
}

/** Audio Player*/

.Player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  position: relative;
  height: 360px;
  width: 360px;
}

.Player .controls {
  display: grid;
  grid-template: "prev play next";
  justify-content: center;
  position: relative;
  z-index: 3;
}

.Player .controls .pause-play-song {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: rgba(97,218,251,0.8) 3px solid;
  border-radius: 50%;
  padding: 0.5rem;
  width: 104px;
  height: 104px;
}

.Player .song-footer {
  color: rgba(97,218,251,0.8);
  position: absolute;
  z-index: 2;
  bottom: 2rem;
  width: 100%;
}

.player-loader {
  max-width: 100%;
  border-radius: inherit;
}

/** Line Eq*/